import { AutoScroll } from '@getuniq/splide-extension-auto-scroll'
import {
  Options,
  Splide,
  SplideSlide,
  SplideTrack
} from '@splidejs/react-splide'
import clsx from 'clsx'
import dynamic from 'next/dynamic'
import { FC, useState } from 'react'

import useMediaQuery from '@/hooks/useMediaQuery'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import GoogleAds from '../../../public/images/partners/google-ads.svg'
import MyTarget from '../../../public/images/partners/my-target.svg'
import Telegram from '../../../public/images/partners/telegram.svg'
import TikTok from '../../../public/images/partners/tik-tok.svg'
import VkAds from '../../../public/images/partners/vk-ads.svg'
import Vk from '../../../public/images/partners/vk.svg'
import YaBusiness from '../../../public/images/partners/ya-business.svg'
import Yandex from '../../../public/images/partners/yandex.svg'

import styles from './Partners.module.scss'

const Animation = dynamic(() => import('@/components/Animation'), {
  ssr: false
})

const PARTNERS = [
  {
    name: 'yandex',
    Icon: Yandex
  },
  {
    name: 'tik-tok',
    Icon: TikTok
  },
  {
    name: 'my-target',
    Icon: MyTarget
  },
  {
    name: 'telegram',
    Icon: Telegram
  },
  {
    name: 'ya-business',
    Icon: YaBusiness
  },
  {
    name: 'vk',
    Icon: Vk
  },
  {
    name: 'google-ads',
    Icon: GoogleAds
  },
  {
    name: 'vk-ads',
    Icon: VkAds
  }
]

const OPTIONS: Options = {
  autoHeight: true,
  autoWidth: true,
  arrows: false,
  classes: {
    pagination: `splide__pagination ${styles['partners__splide-pagination']}`,
    page: `splide__pagination__page  ${styles['partners__splide-page']}`
  },
  pagination: false,
  mediaQuery: 'min',
  type: 'loop',
  drag: false,
  focus: 'center',
  autoScroll: {
    speed: 1,
    pauseOnHover: false,
    autoStart: 'immediately'
  },
  breakpoints: {
    320: {
      gap: '5.5rem'
    },
    1024: {
      gap: '6.5rem'
    },
    1680: {
      gap: '11rem'
    }
  }
}

const PartnersCarousel: FC<DefaultComponentProps> = ({ className = '' }) => {
  const [animationStart, setAnimationStart] = useState(false)
  const isMiddleScreen = useMediaQuery('(min-width: 768px)')
  const handleReady = () => {
    setAnimationStart(true)
  }

  return (
    <div
      className={clsx(styles['partners'], className, {
        [styles['partners_animation-start']]: animationStart
      })}
    >
      {isMiddleScreen && (
        <Animation
          lang={false}
          type={'pacman'}
          className={styles['partners__pacman']}
        />
      )}

      <Splide
        hasTrack={false}
        options={OPTIONS}
        extensions={{ AutoScroll }}
        onReady={handleReady}
      >
        <SplideTrack>
          {PARTNERS.map(({ name, Icon }, index) => (
            <SplideSlide
              role={'section'}
              key={name + index}
              className={styles['partners__item']}
            >
              <div className={styles['partners__fig']}>
                <Icon />
              </div>
            </SplideSlide>
          ))}
        </SplideTrack>
      </Splide>
    </div>
  )
}
export default PartnersCarousel
